import * as React from "react";
import App from "./App";
import SEO from "./SEO";

import "semantic-ui-css/semantic.min.css";
import "./style.css";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <App />
    </>
  );
};

export default IndexPage;
